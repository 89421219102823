import { type HeadersFunction, type MetaFunction, redirect } from "@remix-run/cloudflare";
import { useLoaderData } from "@remix-run/react";
import Carousel from "~/components/carousel.component";
import Modal from "~/components/modal.component";
import Neum from "~/components/neum.component";
import { MIN_QUANTITY_AVAILABLE } from "~/constants";
import { getRecentProducts } from "~/shopify/API/recent-products.server";
import type { ProductFlatten } from "~/types";
import { getSubdomain, getSubdomainCategory } from "~/utils/subdomain";
import beauty from "../images/homepage/beauty.jpg";
import electronics from "../images/homepage/electronics.jpg";
import fashion from "../images/homepage/fashion.jpg";
import homepage from "../images/homepage/home-admirer.jpg";
import home from "../images/homepage/home.jpg";
import kids from "../images/homepage/kids.jpg";
import pets from "../images/homepage/pets.jpg";
import sports from "../images/homepage/sports.jpg";
import travel from "../images/homepage/travel.jpg";

// import AlertModal from "~/components/alertModal.component";

type LoaderData = { products: ProductFlatten[] };

export async function loader({ request }: { request: Request }) {
  const subdomain = getSubdomain(request.url);
  if (subdomain) {
    const subdomainCategory = getSubdomainCategory(subdomain);
    if (subdomainCategory) {
      return redirect(`/category/${subdomainCategory}`);
    }
  }
  const products = await getRecentProducts();

  return { products: products.filter((product) => (product.totalInventory ?? 0) >= MIN_QUANTITY_AVAILABLE) };
}

export const meta: MetaFunction = () => {
  return [
    {
      description:
        "Welcome to Admirer Store, your ultimate destination for seamless and enjoyable online shopping. We are a customer-centric e-commerce platform specializing in a wide variety of premium products. Join us at Admirer Store, where the joy of shopping meets efficiency and reliability. Experience the excitement of discovering new products that reflect your unique style. Shop with us today and become an Admirer!",
      keywords:
        "Beauty and health, Pets, Kids, Electronics, Home and Kitchen, Fashion, Travel, Fast shipping, Secure Payments, Free Shipping",
      robots: "index,follow",
      "og:title": "Admirer Store | The fastest way to buy",
      "og:description":
        "Welcome to Admirer Store, your ultimate destination for seamless and enjoyable online shopping. We are a customer-centric e-commerce platform specializing in a wide variety of premium products. Join us at Admirer Store, where the joy of shopping meets efficiency and reliability. Experience the excitement of discovering new products that reflect your unique style. Shop with us today and become an Admirer!",
      "og:image": homepage,
    },
  ];
};

export const headers: HeadersFunction = () => {
  // data cached 10 minutes in the browser, one hour(3600) in the server-cdn, stale while revalidate for 24 hours(86400)
  return {
    "cache-control": "public, max-age=600, s-maxage=3600, stale-while-revalidate=86400",
  };
};

export default function Index() {
  const { products } = useLoaderData() as LoaderData;

  return (
    <main>
      <div className="flex w-full justify-between">
        <h1 className="text-shadow-2xl pl-1 pt-4 text-xl font-bold leading-5 sm:pt-8 sm:text-3xl">Shop by Category</h1>
      </div>

      <div className="categories grid auto-rows-max grid-cols-12 gap-3 py-3">
        <Neum className="col-span-12">
          <a href="/category/beauty-health">
            <h1 className="absolute left-0 w-full text-center text-xs font-bold text-white sm:bottom-[5.25rem] sm:left-6 sm:text-left sm:text-2xl sm:font-extrabold ">
              <span className="relative top-9 m-auto block w-32 rounded-lg bg-admirerBlue-900 bg-opacity-50 py-1 backdrop-blur-sm sm:absolute sm:w-auto sm:rounded-b-none sm:rounded-t-lg sm:px-4 sm:py-2">
                Beauty and Health
              </span>
            </h1>
            <img
              className="min-h-xs-cat-width-full rounded-lg object-cover md:min-h-md-cat-width-full"
              src={beauty}
              alt="Beauty and Health"
            />
          </a>
        </Neum>

        <Neum className="col-span-6 sm:col-span-5">
          <a href="https://pets.admirer.store">
            <h1 className="absolute bottom-3/4 left-0 w-full text-center text-xs font-bold text-white sm:bottom-[5.5rem] sm:left-6 sm:text-left sm:text-2xl sm:font-extrabold ">
              <span className="relative top-8 m-auto block w-12 rounded-lg bg-admirerBlue-900 bg-opacity-50 py-1 backdrop-blur-sm sm:absolute sm:top-10 sm:w-auto sm:rounded-b-none sm:rounded-t-lg sm:px-4 sm:py-2">
                Pets
              </span>
            </h1>
            <img
              className="min-h-xs-cat-half-full rounded-lg object-cover md:min-h-md-cat-half-full"
              src={pets}
              alt="Pets"
            />
          </a>
        </Neum>
        <Neum className="col-span-6 row-span-2 sm:col-span-7">
          <a className="" href="/category/electronics">
            <h1 className="absolute inset-1/4 bottom-3/4 left-0 w-full text-center text-xs font-bold text-white sm:inset-auto sm:bottom-[5.5rem] sm:left-6 sm:text-left sm:text-2xl sm:font-extrabold ">
              <span className="relative top-10 m-auto block w-20 rounded-lg bg-admirerBlue-900 bg-opacity-50 py-1 backdrop-blur-sm sm:absolute sm:w-auto sm:rounded-b-none sm:rounded-t-lg sm:px-4 sm:py-2">
                Electronics
              </span>
            </h1>
            <img
              className="min-h-xs-cat-height-full rounded-lg object-cover md:min-h-md-cat-height-full"
              src={electronics}
              alt="Electronics"
            />
          </a>
        </Neum>
        <Neum className="col-span-6 sm:col-span-5">
          <a href="/category/kids">
            <h1 className="absolute bottom-3/4 left-0 w-full text-center text-xs font-bold text-white sm:bottom-[5.5rem] sm:left-6 sm:text-left sm:text-2xl sm:font-extrabold ">
              <span className="relative top-8 m-auto block w-12 rounded-lg bg-admirerBlue-900 bg-opacity-50 py-1 backdrop-blur-sm sm:absolute sm:top-10 sm:w-auto sm:rounded-b-none sm:rounded-t-lg sm:px-4 sm:py-2">
                Kids
              </span>
            </h1>
            <img
              className=" min-h-xs-cat-half-full rounded-lg object-cover md:min-h-md-cat-half-full"
              src={kids}
              alt="Kids"
            />
          </a>
        </Neum>

        <Neum className="col-span-12">
          <a href="/category/home-kitchen">
            <h1 className="absolute left-0 w-full text-center text-xs font-bold text-white sm:bottom-[5.25rem] sm:left-6 sm:text-left sm:text-2xl sm:font-extrabold ">
              <span className="relative top-9 m-auto block w-32 rounded-lg bg-admirerBlue-900 bg-opacity-50 py-1 backdrop-blur-sm sm:absolute sm:w-auto sm:rounded-b-none sm:rounded-t-lg sm:px-4 sm:py-2">
                Home and Kitchen
              </span>
            </h1>
            <img
              className="min-h-xs-cat-width-full rounded-lg object-cover md:min-h-md-cat-width-full"
              src={home}
              alt="Home and Kitchen"
            />
          </a>
        </Neum>

        <Neum className="col-span-6 row-span-2 sm:col-span-7">
          <a href="/category/fashion">
            <h1 className="absolute inset-1/4 bottom-3/4 left-0 w-full text-center text-xs font-bold text-white sm:inset-auto sm:bottom-[5.5rem] sm:left-6 sm:text-left sm:text-2xl sm:font-extrabold ">
              <span className="relative top-10 m-auto block w-20 rounded-lg bg-admirerBlue-900 bg-opacity-50 py-1 backdrop-blur-sm sm:absolute sm:w-auto sm:rounded-b-none sm:rounded-t-lg sm:px-4 sm:py-2">
                Fashion
              </span>
            </h1>
            <img
              className="min-h-xs-cat-height-full rounded-lg object-cover md:min-h-md-cat-height-full"
              src={fashion}
              alt="Fashion"
            />
          </a>
        </Neum>
        <Neum className="col-span-6 sm:col-span-5">
          <a href="/category/travel">
            <h1 className="absolute bottom-3/4 left-0 w-full text-center text-xs font-bold text-white sm:bottom-[5.5rem] sm:left-6 sm:text-left sm:text-2xl sm:font-extrabold ">
              <span className="relative top-8 m-auto block w-12 rounded-lg bg-admirerBlue-900 bg-opacity-50 py-1 backdrop-blur-sm sm:absolute sm:top-10 sm:w-auto sm:rounded-b-none sm:rounded-t-lg sm:px-4 sm:py-2">
                Travel
              </span>
            </h1>
            <img
              className="min-h-xs-cat-half-full rounded-lg object-cover md:min-h-md-cat-half-full"
              src={travel}
              alt="Travel"
            />
          </a>
        </Neum>

        <Neum className="col-span-6 sm:col-span-5">
          <a href="/category/sports">
            <h1 className="absolute bottom-3/4 left-0 w-full text-center text-xs font-bold text-white sm:bottom-[5.5rem] sm:left-6 sm:text-left sm:text-2xl sm:font-extrabold ">
              <span className="relative top-8 m-auto block w-12 rounded-lg bg-admirerBlue-900 bg-opacity-50 py-1 backdrop-blur-sm sm:absolute sm:top-10 sm:w-auto sm:rounded-b-none sm:rounded-t-lg sm:px-4 sm:py-2">
                Sports
              </span>
            </h1>
            <img
              className="min-h-xs-cat-half-full rounded-lg object-cover md:min-h-md-cat-half-full"
              src={sports}
              alt="Sports"
            />
          </a>
        </Neum>
      </div>
      <Carousel products={products} title="Recent Products" />
    </main>
  );
}
